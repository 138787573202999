.normalized-passage-tool-container {
    // background-color: "#f6f4f9";
    height: 100%;
    width: 100%;
    overflow: auto;
  }
  
  .plate-info-container {
    height: "200px";
    width: "300px";
    margin-top: "5px";
    // background-color: green;
  }
  
  .toggle-buttons {
    margin-bottom: 50px;
  }
  
  .toggle-buttons:hover {
    opacity: 0.2;
    cursor: pointer;
  }
  
  .plate-well:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  
  .selectable-selectbox {
    z-index: 9000;
    position: relative;
    cursor: default;
    background: none;
    border: 1px dashed cyan;
  }
  
  .plate-well {
    border: "1px solid black";
    height: "100%";
    width: "100%";
    border-radius: "50%";
  }
  
  .selecting {
    opacity: 0.4;
  }
  
  .selected {
    opacity: 0.3;
  }
  
  .close-icon:hover {
    cursor: pointer;
  }
  
  .drawer-item:hover {
    cursor: pointer;
  }